import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  PasswordInput,
  required,
  minLength
} from 'react-admin';

const validatePassword = [required(), minLength(8)];

const AdminEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source='name' validate={required()} />
      <TextInput source='username' validate={required()} />
      <PasswordInput source='password' validate={validatePassword} />
    </SimpleForm>
  </Edit>
);

export default AdminEdit;
