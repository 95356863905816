export default [
    {index: 0, en: "Afghanistan", ar: "أفغانستان", code: "+93", iso: "AF"},
    {index: 1, en: "Albania", ar: "ألبانيا", code: "+355", iso: "AL"},
    {index: 2, en: "Algeria", ar: "الجزائر", code: "+213", iso: "DZ"},
    {index: 3, en: "American Samoa", ar: "ساموا الأمريكية", code: "+1-684", iso: "AS"},
    {index: 4, en: "Andorra", ar: "أندورا", code: "+376", iso: "AD"},
    {index: 5, en: "Angola", ar: "أنغولا", code: "+244", iso: "AO"},
    {index: 6, en: "Anguilla", ar: "أنغيلا", code: "+1-264", iso: "AI"},
    {index: 7, en: "Antarctica", ar: "أنتاركتيكا", code: "+672", iso: "AQ"},
    {index: 8, en: "Antigua and Barbuda", ar: "أنتيغوا وبربودا", code: "+1-268", iso: "AG"},
    {index: 9, en: "Argentina", ar: "الأرجنتين", code: "+54", iso: "AR"},
    {index: 10, en: "Armenia", ar: "أرمينيا", code: "+374", iso: "AM"},
    {index: 11, en: "Aruba", ar: "أروبا", code: "+297", iso: "AW"},
    {index: 12, en: "Australia", ar: "أستراليا", code: "+61", iso: "AU"},
    {index: 13, en: "Austria", ar: "النمسا", code: "+43", iso: "AT"},
    {index: 14, en: "Azerbaijan", ar: "أذربيجان", code: "+994", iso: "AZ"},
    {index: 15, en: "Bahamas", ar: "جزر البهاما", code: "+1-242", iso: "BS"},
    {index: 16, en: "Bahrain", ar: "البحرين", code: "+973", iso: "BH"},
    {index: 17, en: "Bangladesh", ar: "بنغلاديش", code: "+880", iso: "BD"},
    {index: 18, en: "Barbados", ar: "بربادوس", code: "+1-246", iso: "BB"},
    {index: 19, en: "Belarus", ar: "روسيا البيضاء", code: "+375", iso: "BY"},
    {index: 20, en: "Belgium", ar: "بلجيكا", code: "+32", iso: "BE"},
    {index: 21, en: "Belize", ar: "بليز", code: "+501", iso: "BZ"},
    {index: 22, en: "Benin", ar: "بنين", code: "+229", iso: "BJ"},
    {index: 23, en: "Bermuda", ar: "برمودا", code: "+1-441", iso: "BM"},
    {index: 24, en: "Bhutan", ar: "بوتان", code: "+975", iso: "BT"},
    {index: 25, en: "Bolivia", ar: "بوليفيا", code: "+591", iso: "BO"},
    {index: 26, en: "Bosnia and Herzegovina", ar: "البوسنة والهرسك", code: "+387", iso: "BA"},
    {index: 27, en: "Botswana", ar: "بوتسوانا", code: "+267", iso: "BW"},
    {index: 28, en: "Brazil", ar: "البرازيل", code: "+55", iso: "BR"},
    {index: 29, en: "British Indian Ocean Territory", ar: "إقليم المحيط البريطاني الهندي", code: "+246", iso: "IO"},
    {index: 30, en: "British Virgin Islands", ar: "جزر فيرجن البريطانية", code: "+1-284", iso: "VG"},
    {index: 31, en: "Brunei", ar: "بروناي", code: "+673", iso: "BN"},
    {index: 32, en: "Bulgaria", ar: "بلغاريا", code: "+359", iso: "BG"},
    {index: 33, en: "Burkina Faso", ar: "بوركينا فاسو", code: "+226", iso: "BF"},
    {index: 34, en: "Burundi", ar: "بوروندي", code: "+257", iso: "BI"},
    {index: 35, en: "Cambodia", ar: "كمبوديا", code: "+855", iso: "KH"},
    {index: 36, en: "Cameroon", ar: "الكاميرون", code: "+237", iso: "CM"},
    {index: 37, en: "Canada", ar: "كندا", code: "+1", iso: "CA"},
    {index: 38, en: "Cape Verde", ar: "الرأس الأخضر", code: "+238", iso: "CV"},
    {index: 39, en: "Cayman Islands", ar: "جزر كايمان", code: "+1-345", iso: "KY"},
    {index: 40, en: "Central African Republic", ar: "جمهورية افريقيا الوسطى", code: "+236", iso: "CF"},
    {index: 41, en: "Chad", ar: "تشاد", code: "+235", iso: "TD"},
    {index: 42, en: "Chile", ar: "تشيلي", code: "+56", iso: "CL"},
    {index: 43, en: "China", ar: "الصين", code: "+86", iso: "CN"},
    {index: 44, en: "Christmas Island", ar: "جزيرة الكريسماس", code: "+61", iso: "CX"},
    {index: 45, en: "Cocos Islands", ar: "جزر كوكوس", code: "+61", iso: "CC"},
    {index: 46, en: "Colombia", ar: "كولومبيا", code: "+57", iso: "CO"},
    {index: 47, en: "Comoros", ar: "جزر القمر", code: "+269", iso: "KM"},
    {index: 48, en: "Cook Islands", ar: "جزر كوك", code: "+682", iso: "CK"},
    {index: 49, en: "Costa Rica", ar: "كوستا ريكا", code: "+506", iso: "CR"},
    {index: 50, en: "Croatia", ar: "كرواتيا", code: "+385", iso: "HR"},
    {index: 51, en: "Cuba", ar: "كوبا", code: "+53", iso: "CU"},
    {index: 52, en: "Curacao", ar: "كوراكاو", code: "+599", iso: "CW"},
    {index: 53, en: "Cyprus", ar: "قبرص", code: "+357", iso: "CY"},
    {index: 54, en: "Czech Republic", ar: "جمهورية التشيك", code: "+420", iso: "CZ"},
    {index: 55, en: "Democratic Republic of the Congo", ar: "جمهورية الكونغو الديموقراطية", code: "+243", iso: "CD"},
    {index: 56, en: "Denmark", ar: "الدنمارك", code: "+45", iso: "DK"},
    {index: 57, en: "Djibouti", ar: "جيبوتي", code: "+253", iso: "DJ"},
    {index: 58, en: "Dominica", ar: "دومينيكا", code: "+1-767", iso: "DM"},
    {index: 59, en: "Dominican Republic", ar: "جمهورية الدومنيكان", code: "+1-809", iso: "DO"}, // +1-829, +1-849
    {index: 60, en: "East Timor", ar: "تيمور الشرقية", code: "+670", iso: "TL"},
    {index: 61, en: "Ecuador", ar: "إكوادور", code: "+593", iso: "EC"},
    {index: 62, en: "Egypt", ar: "مصر", code: "+20", iso: "EG"},
    {index: 63, en: "El Salvador", ar: "السلفادور", code: "+503", iso: "SV"},
    {index: 64, en: "Equatorial Guinea", ar: "غينيا الإستوائية", code: "+240", iso: "GQ"},
    {index: 65, en: "Eritrea", ar: "إريتريا", code: "+291", iso: "ER"},
    {index: 66, en: "Estonia", ar: "إستونيا", code: "+372", iso: "EE"},
    {index: 67, en: "Ethiopia", ar: "أثيوبيا", code: "+251", iso: "ET"},
    {index: 68, en: "Falkland Islands", ar: "جزر فوكلاند", code: "+500", iso: "FK"},
    {index: 69, en: "Faroe Islands", ar: "جزر فاروس", code: "+298", iso: "FO"},
    {index: 70, en: "Fiji", ar: "فيجي", code: "+679", iso: "FJ"},
    {index: 71, en: "Finland", ar: "فنلندا", code: "+358", iso: "FI"},
    {index: 72, en: "France", ar: "فرنسا", code: "+33", iso: "FR"},
    {index: 73, en: "French Polynesia", ar: "بولينيزيا الفرنسية", code: "+689", iso: "PF"},
    {index: 74, en: "Gabon", ar: "الغابون", code: "+241", iso: "GA"},
    {index: 75, en: "Gambia", ar: "غامبيا", code: "+220", iso: "GM"},
    {index: 76, en: "Georgia", ar: "جورجيا", code: "+995", iso: "GE"},
    {index: 77, en: "Germany", ar: "ألمانيا", code: "+49", iso: "DE"},
    {index: 78, en: "Ghana", ar: "غانا", code: "+233", iso: "GH"},
    {index: 79, en: "Gibraltar", ar: "جبل طارق", code: "+350", iso: "GI"},
    {index: 80, en: "Greece", ar: "اليونان", code: "+30", iso: "GR"},
    {index: 81, en: "Greenland", ar: "الأرض الخضراء", code: "+299", iso: "GL"},
    {index: 82, en: "Grenada", ar: "غرينادا", code: "+1-473", iso: "GD"},
    {index: 83, en: "Guam", ar: "غوام", code: "+1-671", iso: "GU"},
    {index: 84, en: "Guatemala", ar: "غواتيمالا", code: "+502", iso: "GT"},
    {index: 85, en: "Guernsey", ar: "غيرنسي", code: "+44-1481", iso: "GG"},
    {index: 86, en: "Guinea", ar: "غينيا", code: "+224", iso: "GN"},
    {index: 87, en: "Guinea-Bissau", ar: "غينيا - بيساو", code: "+245", iso: "GW"},
    {index: 88, en: "Guyana", ar: "غيانا", code: "+592", iso: "GY"},
    {index: 89, en: "Haiti", ar: "هايتي", code: "+509", iso: "HT"},
    {index: 90, en: "Honduras", ar: "هندوراس", code: "+504", iso: "HN"},
    {index: 91, en: "Hong Kong", ar: "هونج كونج", code: "+852", iso: "HK"},
    {index: 92, en: "Hungary", ar: "هنغاريا", code: "+36", iso: "HU"},
    {index: 93, en: "Iceland", ar: "أيسلندا", code: "+354", iso: "IS"},
    {index: 94, en: "India", ar: "الهند", code: "+91", iso: "IN"},
    {index: 95, en: "Indonesia", ar: "إندونيسيا", code: "+62", iso: "ID"},
    {index: 96, en: "Iran", ar: "إيران", code: "+98", iso: "IR"},
    {index: 97, en: "Iraq", ar: "العراق", code: "+964", iso: "IQ"},
    {index: 98, en: "Ireland", ar: "أيرلندا", code: "+353", iso: "IE"},
    {index: 99, en: "Isle of Man", ar: "جزيرة آيل أوف مان", code: "+44-1624", iso: "IM"},
    {index: 100, en: "Italy", ar: "إيطاليا", code: "+39", iso: "IT"},
    {index: 101, en: "Ivory Coast", ar: "ساحل العاج", code: "+225", iso: "CI"},
    {index: 102, en: "Jamaica", ar: "جامايكا", code: "+1-876", iso: "JM"},
    {index: 103, en: "Japan", ar: "اليابان", code: "+81", iso: "JP"},
    {index: 104, en: "Jersey", ar: "جيرسي", code: "+44-1534", iso: "JE"},
    {index: 105, en: "Jordan", ar: "الأردن", code: "+962", iso: "JO"},
    {index: 106, en: "Kazakhstan", ar: "كازاخستان", code: "+7", iso: "KZ"},
    {index: 107, en: "Kenya", ar: "كينيا", code: "+254", iso: "KE"},
    {index: 108, en: "Kiribati", ar: "كيريباتي", code: "+686", iso: "KI"},
    {index: 109, en: "Kosovo", ar: "كوسوفو", code: "+383", iso: "XK"},
    {index: 110, en: "Kuwait", ar: "الكويت", code: "+965", iso: "KW"},
    {index: 111, en: "Kyrgyzstan", ar: "قيرغيزستان", code: "+996", iso: "KG"},
    {index: 112, en: "Laos", ar: "لاوس", code: "+856", iso: "LA"},
    {index: 113, en: "Latvia", ar: "لاتفيا", code: "+371", iso: "LV"},
    {index: 114, en: "Lebanon", ar: "لبنان", code: "+961", iso: "LB"},
    {index: 115, en: "Lesotho", ar: "ليسوتو", code: "+266", iso: "LS"},
    {index: 116, en: "Liberia", ar: "ليبيريا", code: "+231", iso: "LR"},
    {index: 117, en: "Libya", ar: "ليبيا", code: "+218", iso: "LY"},
    {index: 118, en: "Liechtenstein", ar: "ليختنشتاين", code: "+423", iso: "LI"},
    {index: 119, en: "Lithuania", ar: "ليتوانيا", code: "+370", iso: "LT"},
    {index: 120, en: "Luxembourg", ar: "لوكسمبورج", code: "+352", iso: "LU"},
    {index: 121, en: "Macau", ar: "ماكاو", code: "+853", iso: "MO"},
    {index: 122, en: "Macedonia", ar: "مقدونيا", code: "+389", iso: "MK"},
    {index: 123, en: "Madagascar", ar: "مدغشقر", code: "+261", iso: "MG"},
    {index: 124, en: "Malawi", ar: "ملاوي", code: "+265", iso: "MW"},
    {index: 125, en: "Malaysia", ar: "ماليزيا", code: "+60", iso: "MY"},
    {index: 126, en: "Maldives", ar: "جزر المالديف", code: "+960", iso: "MV"},
    {index: 127, en: "Mali", ar: "مالي", code: "+223", iso: "ML"},
    {index: 128, en: "Malta", ar: "مالطا", code: "+356", iso: "MT"},
    {index: 129, en: "Marshall Islands", ar: "جزر مارشال", code: "+692", iso: "MH"},
    {index: 130, en: "Mauritania", ar: "موريتانيا", code: "+222", iso: "MR"},
    {index: 131, en: "Mauritius", ar: "موريشيوس", code: "+230", iso: "MU"},
    {index: 132, en: "Mayotte", ar: "مايوت", code: "+262", iso: "YT"},
    {index: 133, en: "Mexico", ar: "المكسيك", code: "+52", iso: "MX"},
    {index: 134, en: "Micronesia", ar: "ميكرونيزيا", code: "+691", iso: "FM"},
    {index: 135, en: "Moldova", ar: "مولدوفا", code: "+373", iso: "MD"},
    {index: 136, en: "Monaco", ar: "موناكو", code: "+377", iso: "MC"},
    {index: 137, en: "Mongolia", ar: "منغوليا", code: "+976", iso: "MN"},
    {index: 138, en: "Montenegro", ar: "الجبل الأسود", code: "+382", iso: "ME"},
    {index: 139, en: "Montserrat", ar: "مونتسيرات", code: "+1-664", iso: "MS"},
    {index: 140, en: "Morocco", ar: "المغرب", code: "+212", iso: "MA"},
    {index: 141, en: "Mozambique", ar: "موزمبيق", code: "+258", iso: "MZ"},
    {index: 142, en: "Myanmar", ar: "ميانمار", code: "+95", iso: "MM"},
    {index: 143, en: "Namibia", ar: "ناميبيا", code: "+264", iso: "NA"},
    {index: 144, en: "Nauru", ar: "ناورو", code: "+674", iso: "NR"},
    {index: 145, en: "Nepal", ar: "نيبال", code: "+977", iso: "NP"},
    {index: 146, en: "Netherlands", ar: "هولندا", code: "+31", iso: "NL"},
    {index: 147, en: "Netherlands Antilles", ar: "جزر الأنتيل الهولندية", code: "+599", iso: "AN"},
    {index: 148, en: "New Caledonia", ar: "كاليدونيا الجديدة", code: "+687", iso: "NC"},
    {index: 149, en: "New Zealand", ar: "نيوزيلندا", code: "+64", iso: "NZ"},
    {index: 150, en: "Nicaragua", ar: "نيكاراغوا", code: "+505", iso: "NI"},
    {index: 151, en: "Niger", ar: "النيجر", code: "+227", iso: "NE"},
    {index: 152, en: "Nigeria", ar: "نيجيريا", code: "+234", iso: "NG"},
    {index: 153, en: "Niue", ar: "نيوي", code: "+683", iso: "NU"},
    {index: 154, en: "North Korea", ar: "كوريا الشماليه", code: "+850", iso: "KP"},
    {index: 155, en: "Northern Mariana Islands", ar: "جزر مريانا الشمالية", code: "+1-670", iso: "MP"},
    {index: 156, en: "Norway", ar: "النرويج", code: "+47", iso: "NO"},
    {index: 157, en: "Oman", ar: "سلطنة عمان", code: "+968", iso: "OM"},
    {index: 158, en: "Pakistan", ar: "باكستان", code: "+92", iso: "PK"},
    {index: 159, en: "Palau", ar: "بالاو", code: "+680", iso: "PW"},
    {index: 160, en: "Palestine", ar: "فلسطين", code: "+970", iso: "PS"},
    {index: 161, en: "Panama", ar: "بنما", code: "+507", iso: "PA"},
    {index: 162, en: "Papua New Guinea", ar: "بابوا غينيا الجديدة", code: "+675", iso: "PG"},
    {index: 163, en: "Paraguay", ar: "باراغواي", code: "+595", iso: "PY"},
    {index: 164, en: "Peru", ar: "بيرو", code: "+51", iso: "PE"},
    {index: 165, en: "Philippines", ar: "الفلبين", code: "+63", iso: "PH"},
    {index: 166, en: "Pitcairn", ar: "بيتكيرن", code: "+64", iso: "PN"},
    {index: 167, en: "Poland", ar: "بولندا", code: "+48", iso: "PL"},
    {index: 168, en: "Portugal", ar: "البرتغال", code: "+351", iso: "PT"},
    {index: 169, en: "Puerto Rico", ar: "بورتوريكو", code: "+1-787", iso: "PR"}, // +1-939
    {index: 170, en: "Qatar", ar: "دولة قطر", code: "+974", iso: "QA"},
    {index: 171, en: "Republic of the Congo", ar: "جمهورية الكونغو", code: "+242", iso: "CG"},
    {index: 172, en: "Reunion", ar: "جمع شمل", code: "+262", iso: "RE"},
    {index: 173, en: "Romania", ar: "رومانيا", code: "+40", iso: "RO"},
    {index: 174, en: "Russia", ar: "روسيا", code: "+7", iso: "RU"},
    {index: 175, en: "Rwanda", ar: "رواندا", code: "+250", iso: "RW"},
    {index: 176, en: "Saint Barthelemy", ar: "سانت بارتيليمي", code: "+590", iso: "BL"},
    {index: 177, en: "Saint Helena", ar: "سانت هيلانة", code: "+290", iso: "SH"},
    {index: 178, en: "Saint Kitts and Nevis", ar: "سانت كيتس ونيفيس", code: "+1-869", iso: "KN"},
    {index: 179, en: "Saint Lucia", ar: "القديسة لوسيا", code: "+1-758", iso: "LC"},
    {index: 180, en: "Saint Martin", ar: "القديس مارتن", code: "+590", iso: "MF"},
    {index: 181, en: "Saint Pierre and Miquelon", ar: "سانت بيير وميكلون", code: "+508", iso: "PM"},
    {index: 182, en: "Saint Vincent and the Grenadines", ar: "سانت فنسنت وجزر غرينادين", code: "+1-784", iso: "VC"},
    {index: 183, en: "Samoa", ar: "ساموا", code: "+685", iso: "WS"},
    {index: 184, en: "San Marino", ar: "سان مارينو", code: "+378", iso: "SM"},
    {index: 185, en: "Sao Tome and Principe", ar: "سان تومي وبرينسيبي", code: "+239", iso: "ST"},
    {index: 186, en: "Saudi Arabia", ar: "المملكة العربية السعودية", code: "+966", iso: "SA"},
    {index: 187, en: "Senegal", ar: "السنغال", code: "+221", iso: "SN"},
    {index: 188, en: "Serbia", ar: "صربيا", code: "+381", iso: "RS"},
    {index: 189, en: "Seychelles", ar: "سيشيل", code: "+248", iso: "SC"},
    {index: 190, en: "Sierra Leone", ar: "سيرا ليون", code: "+232", iso: "SL"},
    {index: 191, en: "Singapore", ar: "سنغافورة", code: "+65", iso: "SG"},
    {index: 192, en: "Sint Maarten", ar: "سينت مارتن", code: "+1-721", iso: "SX"},
    {index: 193, en: "Slovakia", ar: "سلوفاكيا", code: "+421", iso: "SK"},
    {index: 194, en: "Slovenia", ar: "سلوفينيا", code: "+386", iso: "SI"},
    {index: 195, en: "Solomon Islands", ar: "جزر سليمان", code: "+677", iso: "SB"},
    {index: 196, en: "Somalia", ar: "الصومال", code: "+252", iso: "SO"},
    {index: 197, en: "South Africa", ar: "جنوب أفريقيا", code: "+27", iso: "ZA"},
    {index: 198, en: "South Korea", ar: "كوريا الجنوبية", code: "+82", iso: "KR"},
    {index: 199, en: "South Sudan", ar: "جنوب السودان", code: "+211", iso: "SS"},
    {index: 200, en: "Spain", ar: "إسبانيا", code: "+34", iso: "ES"},
    {index: 201, en: "Sri Lanka", ar: "سيريلانكا", code: "+94", iso: "LK"},
    {index: 202, en: "Sudan", ar: "السودان", code: "+249", iso: "SD"},
    {index: 203, en: "Suriname", ar: "سورينام", code: "+597", iso: "SR"},
    {index: 204, en: "Svalbard and Jan Mayen", ar: "سفالبارد وجان ماين", code: "+47", iso: "SJ"},
    {index: 205, en: "Swaziland", ar: "سوازيلاند", code: "+268", iso: "SZ"},
    {index: 206, en: "Sweden", ar: "السويد", code: "+46", iso: "SE"},
    {index: 207, en: "Switzerland", ar: "سويسرا", code: "+41", iso: "CH"},
    {index: 208, en: "Syria", ar: "سوريا", code: "+963", iso: "SY"},
    {index: 209, en: "Taiwan", ar: "تايوان", code: "+886", iso: "TW"},
    {index: 210, en: "Tajikistan", ar: "طاجيكستان", code: "+992", iso: "TJ"},
    {index: 211, en: "Tanzania", ar: "تنزانيا", code: "+255", iso: "TZ"},
    {index: 212, en: "Thailand", ar: "تايلاند", code: "+66", iso: "TH"},
    {index: 213, en: "Togo", ar: "توجو", code: "+228", iso: "TG"},
    {index: 214, en: "Tokelau", ar: "توكيلاو", code: "+690", iso: "TK"},
    {index: 215, en: "Tonga", ar: "تونغا", code: "+676", iso: "TO"},
    {index: 216, en: "Trinidad and Tobago", ar: "ترينداد وتوباغو", code: "+1-868", iso: "TT"},
    {index: 217, en: "Tunisia", ar: "تونس", code: "+216", iso: "TN"},
    {index: 218, en: "Turkey", ar: "ديك رومي", code: "+90", iso: "TR"},
    {index: 219, en: "Turkmenistan", ar: "تركمانستان", code: "+993", iso: "TM"},
    {index: 220, en: "Turks and Caicos Islands", ar: "جزر تركس وكايكوس", code: "+1-649", iso: "TC"},
    {index: 221, en: "Tuvalu", ar: "توفالو", code: "+688", iso: "TV"},
    {index: 222, en: "U.S. Virgin Islands", ar: "جزر فيرجن الأمريكية", code: "+1-340", iso: "VI"},
    {index: 223, en: "Uganda", ar: "أوغندا", code: "+256", iso: "UG"},
    {index: 224, en: "Ukraine", ar: "أوكرانيا", code: "+380", iso: "UA"},
    {index: 225, en: "United Arab Emirates", ar: "الإمارات العربية المتحدة", code: "+971", iso: "AE"},
    {index: 226, en: "United Kingdom", ar: "المملكة المتحدة", code: "+44", iso: "GB"},
    {index: 227, en: "United States", ar: "الولايات المتحدة الأمريكية", code: "+1", iso: "US"},
    {index: 228, en: "Uruguay", ar: "أوروغواي", code: "+598", iso: "UY"},
    {index: 229, en: "Uzbekistan", ar: "أوزبكستان", code: "+998", iso: "UZ"},
    {index: 230, en: "Vanuatu", ar: "فانواتو", code: "+678", iso: "VU"},
    {index: 231, en: "Vatican", ar: "الفاتيكان", code: "+379", iso: "VA"},
    {index: 232, en: "Venezuela", ar: "فنزويلا", code: "+58", iso: "VE"},
    {index: 233, en: "Vietnam", ar: "فيتنام", code: "+84", iso: "VN"},
    {index: 234, en: "Wallis and Futuna", ar: "واليس وفوتونا", code: "+681", iso: "WF"},
    {index: 235, en: "Western Sahara", ar: "الصحراء الغربية", code: "+212", iso: "EH"},
    {index: 236, en: "Yemen", ar: "اليمن", code: "+967", iso: "YE"},
    {index: 237, en: "Zambia", ar: "زامبيا", code: "+260", iso: "ZM"},
    {index: 238, en: "Zimbabwe", ar: "زيمبابوي", code: "+263", iso: "ZW"}
  ];