import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/styles';
import {useHistory} from 'react-router';
import api from '../services/api.js';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import {UserRoles, ReservationTypes} from '../enums';
import Button from '../components/CustomButtons/Button';

const DeleteButton = withStyles({
  root: {
    background: '#ff4444',
    // textTransform: 'uppercase',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ff4444',
      color: 'white',
    },
  },
  label: {
    textTransform: 'uppercase',
  },
})(Button);

const NoShowButton = withStyles({
  root: {
    background: '#ffa726',
    // textTransform: 'uppercase',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ffa726',
      color: 'white',
    },
  },
  label: {
    textTransform: 'uppercase',
  },
})(Button);

const PaidButton = withStyles({
  root: {
    background: '#4CAF50',
    // textTransform: 'uppercase',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4CAF50',
      color: 'white',
    },
  },
  label: {
    textTransform: 'uppercase',
  },
})(Button);

function renderEventContent(eventInfo) {
  return (
    <div
      style={{
        background: eventInfo.backgroundColor,
        width: '100%',
        cursor: 'pointer',
      }}>
      <b>{eventInfo.timeText} </b>
      <i>
        {eventInfo.view.type === 'dayGridMonth'
          ? eventInfo.event.title.substr(0, 17).replace(',', '')
          : eventInfo.event.title}
      </i>
    </div>
  );
}

const Calendar = (props) => {
  let history = useHistory();
  let calendarComponentRef = React.createRef();
  const [values, setValues] = React.useState({
    from: null,
    to: null,
    reserveeName: '',
    reserveePhone: '',
    reserveeID: '',
    currentEvents: [],
    error: false,
    reserveeNameError: false,
    reserveePhoneError: false,
    reserveeIDError: false,
    reservationId: '',
    eventId: '',
    workingHoursFrom: '00:00:00',
    workingHoursTo: '00:00:00',
    editModal: false,
    createModal: false,
    userType: UserRoles.USER,
    type: ReservationTypes.NORMAL,
    penalty: 0,
    noShowLoading: false,
    paidLoading: false,
    updateLoading: false,
    deleteLoading: false,
    createLoading: false,
    errorMessage: 'Something went wrong please refresh and try again',
  });
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [createLoading, setCreateLoading] = React.useState(false);
  const [paidLoading, setPaidLoading] = React.useState(false);
  const [noShowLoading, setNoShowLoading] = React.useState(false);
  const [apiError, setApiError] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        endDate.setMonth(endDate.getMonth() + 1);

        const events = (
          await api.getEvents(
            props.props.id,
            startDate.toISOString(),
            endDate.toISOString(),
          )
        ).data;
        const location = (await api.getWorkingHours(props.record.locationId))
          .data;
        setValues({
          ...values,
          currentEvents: events,
          workingHoursFrom: location.workingHoursFrom,
          workingHoursTo: location.workingHoursTo,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        });
      } catch (err) {}
    })();
  }, []);

  const handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;
    if (selectInfo.view.type == 'dayGridMonth') {
      calendarApi.gotoDate(selectInfo.startStr);
      calendarApi.changeView('timeGridDay');
    } else {
      setValues({
        ...values,
        from: selectInfo.startStr.replace('Z', '').split('+')[0],
        to: selectInfo.endStr.split('T')[1].replace('Z', '').split('+')[0],
        createModal: true,
      });
    }
  };

  const handleCreateModal = () => {
    setValues({
      ...values,
      error: false,
      createModal: false,
    });
    setApiError(false);
  };

  const handleEditModal = () => {
    setValues({
      ...values,
      error: false,
      editModal: false,
    });
    setApiError(false);
  };

  const handleEventClick = (clickInfo) => {
    let calendarApi = clickInfo.view.calendar;
    if (clickInfo.view.type == 'dayGridMonth') {
      calendarApi.gotoDate(clickInfo.event.startStr);
      calendarApi.changeView('timeGridDay');
    } else {
      if (
        clickInfo.event.extendedProps.type === 'reservation' ||
        clickInfo.event.extendedProps.type === 'paid'
      ) {
        setValues({
          ...values,
          eventId: clickInfo.event.id,
          reservationId: clickInfo.event.extendedProps.dbid,
          from: clickInfo.event.startStr.replace('Z', '').split('+')[0],
          to: clickInfo.event.endStr
            .split('T')[1]
            .replace('Z', '')
            .split('+')[0],
          editModal: true,
          userType: clickInfo.event.extendedProps.userType,
          type: clickInfo.event.extendedProps.type,
          penalty: clickInfo.event.extendedProps.penalty,
        });
      } else if (clickInfo.event.extendedProps.type === 'unavailability') {
        history.push(`/unavailability/${clickInfo.event.extendedProps.dbid}`);
      }
    }
  };

  const createReservation = async () => {
    try {
      setCreateLoading(true);
      let calendarApi = calendarComponentRef.current.getApi();
      var startTime = new Date(values.from);
      var endTime = new Date(values.from.split('T')[0] + 'T' + values.to);
      if (values.to == '00:00' || values.to == '00:00:00') {
        endTime.setDate(endTime.getDate() + 1);
      }
      var difference = endTime.getTime() - startTime.getTime();
      var resultInMinutes = Math.round(difference / 60000);
      var trimmedReserveeName = values.reserveeName.trim();
      var trimmedReserveePhone = values.reserveePhone.replace(/\D/g, '');
      if (resultInMinutes <= 0 && values.to != '00:00' && values.to != '00:00:00') {
        setValues({
          ...values,
          error: true,
        });
        return;
      }
      // Check if the name is not empty and contains at least one space (indicating at least two words)
      if (
        !(
          trimmedReserveeName &&
          trimmedReserveeName.includes(' ') &&
          trimmedReserveeName.split(' ').length >= 2
        )
      ) {
        setValues({
          ...values,
          reserveeNameError: true,
        });
        return;
      }
      if (
        !(trimmedReserveePhone.length == 11) &&
        !(
          trimmedReserveePhone.length == 12 &&
          String(trimmedReserveePhone[0]) == '2'
        )
      ) {
        setValues({
          ...values,
          reserveePhoneError: true,
        });
        return;
      }
      if ( values.reserveeID.length == 0 || values.reserveeID.length > 6)
      {
        setValues({
          ...values,
          reserveeIDError: true,
        });
        return;
      }
      const response = (
        await api.reserveCourt(
          props.props.id,
          startTime.toISOString(),
          resultInMinutes,
          values.reserveeName,
          values.reserveePhone,
          values.reserveeID,
        )
      ).data;
      const currentEvents = (
        await api.getEvents(props.props.id, values.startDate, values.endDate)
      ).data;
      setValues({
        ...values,
        currentEvents: currentEvents,
        error: false,
        createModal: false,
      });
      setApiError(false);
    } catch (err) {
      setApiError(true);
    } finally {
      setCreateLoading(false);
    }
  };

  const deleteReservation = async () => {
    try {
      setDeleteLoading(true);
      await api.changeReservationStatus(
        values.reservationId,
        ReservationTypes.CANCELLED,
      );
      const currentEvents = (
        await api.getEvents(props.props.id, values.startDate, values.endDate)
      ).data;
      setValues({
        ...values,
        currentEvents: currentEvents,
        error: false,
        editModal: false,
      });
      setApiError(false);
    } catch (err) {
      setApiError(true);
    } finally {
      setDeleteLoading(false);
    }
  };

  const noShowReservation = async () => {
    try {
      setNoShowLoading(true);
      await api.changeReservationStatus(
        values.reservationId,
        ReservationTypes.NOSHOW,
      );
      const currentEvents = (
        await api.getEvents(props.props.id, values.startDate, values.endDate)
      ).data;
      setValues({
        ...values,
        currentEvents: currentEvents,
        error: false,
        editModal: false,
      });
      setApiError(false);
    } catch (err) {
      setApiError(true);
    } finally {
      setNoShowLoading(false);
    }
  };

  const paidReservation = async () => {
    try {
      setPaidLoading(true);
      await api.changeReservationStatus(
        values.reservationId,
        ReservationTypes.PAID,
      );
      const currentEvents = (
        await api.getEvents(props.props.id, values.startDate, values.endDate)
      ).data;
      setValues({
        ...values,
        currentEvents: currentEvents,
        error: false,
        editModal: false,
      });
      setApiError(false);
    } catch (err) {
      setApiError(true);
    } finally {
      setPaidLoading(false);
    }
  };

  const updateReservation = async () => {
    try {
      setUpdateLoading(true);
      var startTime = new Date(values.from);
      var endTime = new Date(values.from.split('T')[0] + 'T' + values.to);
      if (values.to == '00:00' || values.to == '00:00:00') {
        endTime.setDate(endTime.getDate() + 1);
      }
      var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
      var resultInMinutes = Math.round(difference / 60000);
      if (resultInMinutes <= 0 && values.to != '00:00' && values.to != '00:00:00') {
        setValues({
          ...values,
          error: true,
        });
        return;
      }
      await api.updateReservation(
        values.reservationId,
        startTime.toISOString(),
        resultInMinutes,
        values.penalty === '' ? 0 : values.penalty,
      );
      const currentEvents = (
        await api.getEvents(props.props.id, values.startDate, values.endDate)
      ).data;

      setValues({
        ...values,
        currentEvents: currentEvents,
        error: false,
        editModal: false,
      });
      setApiError(false);
    } catch (err) {
      setApiError(true);
    } finally {
      setUpdateLoading(false);
    }
  };

  const setDate = (name, value) => {
    setValues({...values, [name]: value});
  };
  const setReserveeName = (reserveeNameValue) => {
    setValues({ ...values, reserveeName: reserveeNameValue });
  };

  const setReserveePhone = (reserveePhoneValue) => {
    setValues({ ...values, reserveePhone: reserveePhoneValue });
  };

  const setReserveeID = (reserveeIDValue) => {
    setValues({ ...values, reserveeID: reserveeIDValue });
  };

  return (
    <div style={{margin: 'auto', width: '80%'}}>
      <Dialog
        onClose={handleCreateModal}
        aria-labelledby='simple-dialog-title'
        open={values.createModal}
        fullWidth={true}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 10,
          }}>
          <DialogTitle id='simple-dialog-title'>Create Reservation</DialogTitle>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'space-between',
              width: '80%',
            }}>
            <div
              style={{
                width: '50%',
              }}>
              <TextField
                style={{width: '80%'}}
                error={values.error}
                margin='normal'
                id='from'
                label='From'
                type='datetime-local'
                value={values.from}
                onChange={(event) => setDate('from', event.target.value)}
                helperText={
                  values.error
                    ? 'Starting time must be less than ending time'
                    : ''
                }
              />
            </div>
            <div
              style={{
                width: '50%',
              }}>
              <TextField
                style={{width: '80%'}}
                error={values.error}
                margin='normal'
                id='to'
                label='To'
                type='time'
                value={values.to}
                onChange={(event) => setDate('to', event.target.value)}
                helperText={
                  values.error
                    ? 'Starting time must be less than ending time'
                    : ''
                }
              />
            </div>
          </div>
          <div
            style={{
              width: '80%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'space-between',
            }}>
            <div style={{ width: '50%' }}>
              <TextField
                style={{ width: '80%' }}
                error={values.reserveeNameError}
                margin='normal'
                id='reservee-name'
                label='Reservee Name'
                type='text'
                value={values.reserveeName}
                onChange={(event) => {
                  setReserveeName(event.target.value);
                }}
                helperText={
                  values.reserveeNameError
                    ? 'Name must be firstname and surname splited by a space'
                    : ''
                }
              />
            </div>
            <div style={{ width: '50%' }}>
              <TextField
                style={{ width: '80%' }}
                error={values.reserveePhoneError}
                margin='normal'
                id='reservee-phone'
                label='Reservee Phone'
                type='text'
                value={values.reserveePhone}
                onChange={(event) => {
                  setReserveePhone(event.target.value);
                }}
                helperText={
                  values.reserveePhoneError
                    ? 'Phone must be 11 number in this format +201xxxxxxxxxx or without +2 '
                    : ''
                }
              />
            </div>
            <div style={{ width: '50%' }}>
              <TextField
                style={{ width: '80%' }}
                error={values.reserveeIDError}
                margin='normal'
                id='reservee-id'
                label='Reservee ID'
                type='text'
                value={values.reserveeID}
                onChange={(event) => {
                  setReserveeID(event.target.value);
                }}
                helperText={
                  values.reserveeIDError ? 'Enter a valid ID ' : ''
                }
              />
            </div>
          </div>
          {apiError ? (
            <h4 style={{color: '#ff4444'}}>{values.errorMessage}</h4>
          ) : null}
          <Button
            onClick={createReservation}
            loading={createLoading}
            variant='contained'
            style={{marginTop: 10}}>
            Create
          </Button>
        </div>
      </Dialog>
      <Dialog
        onClose={handleEditModal}
        aria-labelledby='simple-dialog-title'
        open={values.editModal}
        fullWidth={true}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 10,
          }}>
          <DialogTitle id='simple-dialog-title'>Edit Reservation</DialogTitle>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'space-between',
              width: '80%',
            }}>
            <div
              style={{
                width: '50%',
              }}>
              <TextField
                style={{width: '80%'}}
                error={values.error}
                margin='normal'
                id='from'
                label='From'
                type='datetime-local'
                value={values.from}
                onChange={(event) => setDate('from', event.target.value)}
                helperText={
                  values.error
                    ? 'Starting time must be less than ending time'
                    : ''
                }
              />
            </div>
            <div
              style={{
                width: '50%',
              }}>
              <TextField
                style={{width: '80%'}}
                error={values.error}
                margin='normal'
                id='to'
                label='To'
                type='time'
                value={values.to}
                onChange={(event) => setDate('to', event.target.value)}
                helperText={
                  values.error
                    ? 'Starting time must be less than ending time'
                    : ''
                }
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80%',
            }}>
            <div
              style={{
                width: '50%',
              }}>
              <TextField
                style={{width: '80%'}}
                margin='normal'
                id='penalty'
                label='Penalty'
                type='number'
                value={values.penalty}
                onChange={(event) => setDate('penalty', event.target.value)}
              />
            </div>
          </div>
          {apiError ? (
            <h4 style={{color: '#ff4444'}}>{values.errorMessage}</h4>
          ) : null}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80%',
            }}>
            <DeleteButton
              onClick={deleteReservation}
              loading={deleteLoading}
              variant='contained'
              style={{marginTop: 10, marginRight: 10}}>
              Delete
            </DeleteButton>
            {values.userType === UserRoles.USER ? (
              <NoShowButton
                onClick={noShowReservation}
                loading={noShowLoading}
                variant='contained'
                style={{marginTop: 10, marginRight: 10, marginLeft: 10}}>
                No Show
              </NoShowButton>
            ) : null}
            {values.type === 'reservation' ? (
              <PaidButton
                onClick={paidReservation}
                loading={paidLoading}
                variant='contained'
                style={{marginTop: 10, marginRight: 10, marginLeft: 10}}>
                Paid
              </PaidButton>
            ) : null}
            <Button
              onClick={updateReservation}
              loading={updateLoading}
              variant='contained'
              style={{marginTop: 10, marginLeft: 10}}>
              Update
            </Button>
          </div>
        </div>
      </Dialog>
      <FullCalendar
        // timeZone={'Africa/Cairo'}
        ref={calendarComponentRef}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          momentTimezonePlugin,
        ]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialView='dayGridMonth'
        // editable={true}
        selectable={true}
        dayMaxEvents={true}
        height={600}
        events={values.currentEvents}
        select={handleDateSelect}
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        // slotMinTime={values.workingHoursFrom}
        // slotMaxTime={values.workingHoursTo}
        allDaySlot={false}
      />
    </div>
  );
};

export default Calendar;
