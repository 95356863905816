import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  ReferenceInput,
  SelectInput,
  required,
  minLength,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const useStyles = makeStyles(styles);

const redirect = (basePath, id, data) => `/locations/${data.locationId}/staff`;

const validatePassword = [required(), minLength(8)];

export const validatePasswords = ({password, confirmPassword}) => {
  const errors = {};
  if (password && confirmPassword && password !== confirmPassword) {
    errors.confirmPassword = [
      'The password confirmation is not the same as the password.',
    ];
  }
  return errors;
};

const CourtCreate = (props) => {
  const classes = useStyles();
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput
          source='name'
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source='username'
          validate={required()}
          formClassName={classes.inline}
        />
        <PasswordInput
          source='password'
          validate={validatePassword}
          formClassName={classes.inline}
        />
        <PasswordInput
          source='confirmPassword'
          validate={validatePassword}
          formClassName={classes.inline}
        />
        <ReferenceInput
          source='locationId'
          reference='locations'
          validate={required()}
          formClassName={classes.inline}>
          <SelectInput
            optionText='name'
            optionValue='id'
            formClassName={classes.inline}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default CourtCreate;
