import * as React from "react";
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  BooleanInput,
  ImageField,
  ImageInput,
  TextField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import TimeInput from "../components/TimeInput";
import { validateMaxLength } from "../utils";

export const styles = {
  inline: { display: "inline-block", marginLeft: 32 },
  margin: { marginLeft: 32, marginRight: 32 },
};

const useStyles = makeStyles(styles);

const PreviewImage = ({ record, source }) => {
  if (typeof record == "string") {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const redirect = (basePath, id, data) => `/locations`;

const LocationCreate = (props) => {
  const classes = useStyles();
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput
          source="name"
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source="latLong"
          label="Location(lat, long)"
          formClassName={classes.inline}
          validate={required()}
        />
        <TextInput
          source="email"
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source="phone"
          validate={required()}
          formClassName={classes.inline}
        />
        <TextField label="Working hours:" />
        <TimeInput
          source="workingHoursFrom"
          name="workingHoursFrom"
          formClassName={classes.inline}
          validate={required()}
        />
        <TimeInput
          source="workingHoursTo"
          name="workingHoursTo"
          formClassName={classes.inline}
          validate={required()}
        />
        <ImageInput source="imgUrl" label="Image" accept="image/*">
          <PreviewImage source="imgUrl" />
        </ImageInput>
        <TextInput
          source="description"
          multiline
          resettable
          fullWidth
          formClassName={classes.margin}
          validate={[validateMaxLength(5000), required()]}
        />
        <TextInput
          source="address"
          multiline
          resettable
          fullWidth
          formClassName={classes.margin}
          validate={[validateMaxLength(1000), required()]}
        />
        <BooleanInput source="active" formClassName={classes.inline} />
        <BooleanInput
          source="isFeatured"
          label="Featured"
          formClassName={classes.inline}
        />
      </SimpleForm>
    </Create>
  );
};

export default LocationCreate;
