import AdminIcon from '@material-ui/icons/Lock';

import AdminList from './AdminList';
import AdminEdit from './AdminEdit';
import AdminCreate from './AdminCreate';

export default {
  list: AdminList,
  edit: AdminEdit,
  create: AdminCreate,
  icon: AdminIcon,
};
