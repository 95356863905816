import * as React from "react";
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

export const styles = {
  inline: { display: "inline-block", marginLeft: 32 },
  margin: { marginLeft: 32, marginRight: 32 },
};

const useStyles = makeStyles(styles);

const redirect = (basePath, id, data) => `/locations/${data.locationId}/courts`;

const CourtCreate = (props) => {
  const classes = useStyles();
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput
          source="name"
          validate={required()}
          formClassName={classes.inline}
        />
        <NumberInput
          source="price"
          validate={required()}
          formClassName={classes.inline}
        />
        <NumberInput
          source="penaltyPercentage"
          formClassName={classes.inline}
          validate={required()}
        />
        <ReferenceInput
          source="locationId"
          reference="locations"
          validate={required()}
          formClassName={classes.inline}
        >
          <SelectInput
            optionText="name"
            optionValue="id"
            formClassName={classes.inline}
          />
        </ReferenceInput>
        <BooleanInput source="active" formClassName={classes.inline} />
      </SimpleForm>
    </Create>
  );
};

export default CourtCreate;
