import StaffIcon from '@material-ui/icons/Lock';

import StaffList from './StaffList';
import StaffEdit from './StaffEdit';
import StaffCreate from './StaffCreate';

export default {
  // list: StaffList,
  edit: StaffEdit,
  create: StaffCreate,
  icon: StaffIcon,
};
