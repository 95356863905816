import * as React from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  maxLength,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const validateDescription = [maxLength(10000),required()];
const useStyles = makeStyles(styles);

const PreviewImage = ({record, source}) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const redirect = (basePath, id, data) => `/news`;

const NewsCreate = (props) => {
  const classes = useStyles();

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput
          source='title'
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source='description'
          multiline
          resettable
          fullWidth
          validate={validateDescription}
          formClassName={classes.margin}
        />
        <ImageInput source='imgUrl' label='Image' accept='image/*'>
          <PreviewImage source='imgUrl' />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default NewsCreate;
