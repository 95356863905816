import * as React from 'react';
import {cloneElement} from 'react';
import {
  EditButton,
  List,
  Datagrid,
  TextField,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  Filter,
  TextInput,
} from 'react-admin';

const ListActions = (props) => {
  const {className, exporter, filters, maxResults, ...rest} = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {/* <CreateButton basePath={basePath} /> */}
    </TopToolbar>
  );
};

const EmailField = ({record, source}) => {
  if (record) {
    record = {
      [source]: record.emailVerified === 0 ? 'No' : 'Yes',
    };
  }
  return <TextField record={record} source={source} />;
};

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label='Name' source='name' />
    <TextInput label='Phone' source='phone' />
    <TextInput label='Email' source='email' />
  </Filter>
);

export const UserList = (props) => (
  <List {...props} actions={<ListActions />} filters={<SearchFilter />}>
    <Datagrid>
      <TextField source='name' />
      <TextField source='email' />
      <TextField source='phone' />
      <EmailField source='emailVerified' />
      <TextField source='noshowPenalty' label='Penalty' />
      <TextField source='warnings' />
      <EditButton />
    </Datagrid>
  </List>
);

export default UserList;
