import * as React from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  minLength,
  email,
  BooleanInput,
  PasswordInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import countryPhoneCodes from '../constants/countryPhoneCodes.js';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
  password: {display: 'inline-block'},
  confirmPassword: {display: 'inline-block', marginLeft: 32},
};

const useStyles = makeStyles(styles);

const validateEmail = [required(), email()];
const validatePassword = [required(), minLength(8)];

export const validatePasswords = ({password, confirmPassword}) => {
  const errors = {};
  if (password && confirmPassword && password !== confirmPassword) {
    errors.confirmPassword = [
      'The password confirmation is not the same as the password.',
    ];
  }
  return errors;
};
const redirect = (basePath, id, data) => `/users`;

const UserCreate = (props) => {
  const classes = useStyles();
  const countryCodes = countryPhoneCodes.map((country) => {
    return {
      id: country.index,
      name: country.en,
    };
  });
  return (
    <Create {...props}>
      <SimpleForm validate={validatePasswords} redirect={redirect}>
        <TextInput
          source='name'
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source='email'
          validate={validateEmail}
          formClassName={classes.inline}
        />
        <TextInput
          source='phone'
          validate={required()}
          formClassName={classes.inline}
        />
        <PasswordInput
          source='password'
          validate={validatePassword}
          formClassName={classes.inline}
        />
        <PasswordInput
          source='confirmPassword'
          validate={validatePassword}
          formClassName={classes.inline}
        />
        <BooleanInput source='emailVerified' formClassName={classes.inline} />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
