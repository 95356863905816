import * as React from "react";
import { Link } from "react-router-dom";
import {
  Edit,
  required,
  Button,
  Pagination,
  ReferenceField,
  EditButton,
  TextInput,
  BooleanInput,
  ImageField,
  ImageInput,
  maxLength,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  TextField,
  useEditController,
} from "react-admin";

import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import TimeInput from "../components/TimeInput";
import { validateMaxLength } from "../utils";

export const styles = {
  inline: { display: "inline-block", marginLeft: 32 },
  margin: { marginLeft: 32, marginRight: 32 },
};

const useStyles = makeStyles(styles);

const LocationTitle = ({ record }) => {
  return record ? (
    <span>
      Court &quot;
      {record.name}&quot;
    </span>
  ) : null;
};

const PreviewImage = ({ record, source }) => {
  if (typeof record == "string") {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const validateAddress = [validateMaxLength(1000), required()];
const validateDescription = [validateMaxLength(5000), required()];

const CreateCourtButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/courts/create",
      // Here we specify the initial record for the create view
      state: { record: { locationId: record.id } },
    }}
    label="Add court"
  >
    <AddIcon />
  </Button>
);

const CreateStaffButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/staff/create",
      // Here we specify the initial record for the create view
      state: { record: { locationId: record.id } },
    }}
    label="Add staff"
  >
    <AddIcon />
  </Button>
);

const ActiveField = ({ record, source }) => {
  record = {
    [source]: record?.active == 0 ? "No" : "Yes",
  };
  return <TextField record={record} source={source} />;
};

const LocationEdit = (props) => {
  const classes = useStyles();
  return (
    <Edit title={<LocationTitle />} {...props}>
      <TabbedForm>
        <FormTab label="Details" contentClassName={classes.tab}>
          <TextInput
            source="name"
            validate={required()}
            formClassName={classes.inline}
          />
          <TextInput
            source="latLong"
            label="Location(lat, long)"
            formClassName={classes.inline}
            validate={required()}
          />
          <TextInput
            source="email"
            validate={required()}
            formClassName={classes.inline}
          />
          <TextInput
            source="phone"
            validate={required()}
            formClassName={classes.inline}
          />
          <TextField label="Working hours:" />
          <TimeInput
            source="workingHoursFrom"
            name="workingHoursFrom"
            formClassName={classes.inline}
            validate={required()}
          />
          <TimeInput
            source="workingHoursTo"
            name="workingHoursTo"
            formClassName={classes.inline}
            validate={required()}
          />
          <ImageInput source="imgUrl" label="Image" accept="image/*">
            <PreviewImage source="imgUrl" />
          </ImageInput>
          <TextInput
            source="description"
            multiline
            resettable
            fullWidth
            validate={validateDescription}
            formClassName={classes.margin}
          />
          <TextInput
            source="address"
            multiline
            resettable
            fullWidth
            validate={validateAddress}
            formClassName={classes.margin}
          />
          <BooleanInput source="active" formClassName={classes.inline} />
          <BooleanInput
            source="isFeatured"
            label="Featured"
            formClassName={classes.inline}
          />
        </FormTab>

        <FormTab label="Courts" path="courts">
          <ReferenceManyField
            reference="courts"
            target="locationId"
            addLabel={false}
            pagination={<Pagination />}
            fullWidth
          >
            <Datagrid>
              <TextField source="name" />
              <TextField source="price" />
              <ActiveField source="active" />
              <TextField source="penaltyPercentage" />
              <ReferenceField source="locationId" reference="locations">
                <TextField source="name" />
              </ReferenceField>
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <CreateCourtButton />
        </FormTab>

        <FormTab label="Staff" path="staff">
          <ReferenceManyField
            reference="staff"
            target="locationId"
            addLabel={false}
            pagination={<Pagination />}
            fullWidth
          >
            <Datagrid>
              <TextField source="name" />
              <TextField source="username" />
              <ReferenceField source="locationId" reference="locations">
                <TextField source="name" />
              </ReferenceField>
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <CreateStaffButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default LocationEdit;
