import NewsIcon from '@material-ui/icons/Chat';

import NewsList from './NewsList';
import NewsEdit from './NewsEdit';
import NewsCreate from './NewsCreate';

export default {
  list: NewsList,
  edit: NewsEdit,
  create: NewsCreate,
  icon: NewsIcon,
};
