import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import * as React from "react";
import { cloneElement } from "react";
import {
  BooleanInput,
  Button,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  sanitizeListRestProps,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useListContext,
} from "react-admin";
import { Link } from "react-router-dom";
import { ReservationTypes, UserRoles } from "../enums";
import { getDate } from "../utils";
import Calendar from "./Calendar.js";
// import Stats from "./Stats";
export const styles = {
  inline: { display: "inline-block", marginLeft: 32 },
  margin: { marginLeft: 32, marginRight: 32 },
};

const useStyles = makeStyles(styles);

const CourtTitle = ({ record }) => {
  return record ? (
    <span>
      Court &quot;
      {record.name}&quot;
    </span>
  ) : null;
};

const ReservationType = ({ record, source }) => {
  record = {
    [source]:
      record?.type === ReservationTypes.CANCELLED
        ? "Cancelled"
        : record?.type === ReservationTypes.NORMAL
        ? "Normal"
        : record?.type === ReservationTypes.PAID
        ? "Paid"
        : record?.type === ReservationTypes.NOSHOW
        ? "No Show"
        : "Unknown",
  };
  return <TextField record={record} source={source} />;
};

const ReservationUserType = ({ record, source }) => {
  record = {
    [source]:
      record?.userType === UserRoles.USER
        ? "User"
        : record?.userType == UserRoles.STAFF
        ? "Staff"
        : "Admin",
  };
  return <TextField record={record} source={source} />;
};

const redirect = (basePath, id, data) => `/locations/${data.locationId}/courts`;

const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const TimeField = ({ record, source }) => {
  if (record) {
    record = {
      [source]: tConvert(record[source]),
    };
  }
  return <TextField record={record} source={source} />;
};

const DateField = ({ record, source }) => {
  let newDate = "";
  if (record && record[source]) {
    newDate = getDate(record[source].toString());
  }
  record = {
    [source]: newDate,
  };

  return <TextField record={record} source={source} />;
};

const WeekDaysField = ({ record, source }) => {
  if (record) {
    let days = record.weekDays.sort().map((day) => {
      return weekDays[day];
    });
    record = {
      [source]: days.toString().replace(",", ", "),
    };
  }
  return <TextField record={record} source={source} />;
};

const CustomToolbar = (props) => {
  return (
    <Toolbar
      {...props}
      classes={useStyles()}
      style={{ justifyContent: "space-between" }}
    >
      <SaveButton />
      <DeleteButton
        undoable={false}
        redirect={`/locations/${props.record.locationId}/courts`}
      />
    </Toolbar>
  );
};

const CreateUnavailabilityButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/unavailability/create",
      // Here we specify the initial record for the create view
      state: { record: { courtId: record.id } },
    }}
    label="Add unavailability"
  >
    <AddIcon />
  </Button>
);

const CreatePriceButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: "/prices/create",
      // Here we specify the initial record for the create view
      state: { record: { courtId: record.id } },
    }}
    label="Add court price"
  >
    <AddIcon />
  </Button>
);

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {/* <CreateButton basePath={basePath} /> */}
    </TopToolbar>
  );
};

const PaidListFilters = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput
        source="from"
        type="date"
        InputLabelProps={{ shrink: true }}
        alwaysOn
      />
      <TextInput
        source="to"
        type="date"
        InputLabelProps={{ shrink: true }}
        alwaysOn
      />
    </Filter>
  );
};

const CourtEdit = (props) => {
  const classes = useStyles();
  return (
    <Edit title={<CourtTitle />} {...props}>
      <TabbedForm redirect={redirect} toolbar={<CustomToolbar />}>
        <FormTab label="Details" contentClassName={classes.tab}>
          <TextInput
            source="name"
            validate={required()}
            formClassName={classes.inline}
          />
          <NumberInput
            source="price"
            validate={required()}
            formClassName={classes.inline}
          />
          <NumberInput
            source="penaltyPercentage"
            formClassName={classes.inline}
            validate={required()}
          />
          <ReferenceInput
            source="locationId"
            reference="locations"
            validate={required()}
            formClassName={classes.inline}
          >
            <SelectInput
              // optionText='name'
              optionValue="id"
              formClassName={classes.inline}
            />
          </ReferenceInput>
          <BooleanInput source="active" formClassName={classes.inline} />
        </FormTab>
        <FormTab label="Calendar" path="calendar">
          <Calendar props={props}></Calendar>
        </FormTab>

        <FormTab label="Unavailability" path="unavailability">
          <ReferenceManyField
            reference="unavailability"
            target="courtId"
            addLabel={false}
            pagination={<Pagination />}
            fullWidth
          >
            <Datagrid>
              <ReferenceField source="courtId" reference="courts">
                <TextField source="name" />
              </ReferenceField>
              <WeekDaysField source="weekDays" label="Days" />
              <TextField source="startDate" />
              <TextField source="endDate" />
              <TimeField source="from" />
              <TimeField source="to" />
              <TextField source="reason" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <CreateUnavailabilityButton />
        </FormTab>

        <FormTab label="Prices" path="prices">
          <ReferenceManyField
            reference="prices"
            target="courtId"
            addLabel={false}
            pagination={<Pagination />}
            fullWidth
          >
            <Datagrid>
              <ReferenceField source="courtId" reference="courts">
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="price" />
              <WeekDaysField source="weekDays" label="Days" />
              <TimeField source="from" />
              <TimeField source="to" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <CreatePriceButton />
        </FormTab>

        <FormTab label="No Show/Cancelled" path="noshow">
          <ReferenceManyField
            reference="noshow"
            target="courtId"
            addLabel={false}
            pagination={<Pagination />}
            fullWidth
          >
            <Datagrid>
              <ReferenceField source="courtId" reference="courts">
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="price" />
              <NumberField source="penaltyAdded" />
              <ReservationType source="type" />
              <ReservationUserType source="userType" />
              <DateField source="from" />
              <DateField source="to" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>

        <FormTab label="Paid" path="paid">
          <ReferenceManyField
            reference="paid"
            target="courtId"
            addLabel={false}
            // pagination={<Pagination />}
            filter={{ courtId: props.id }}
            fullWidth
          >
            <List
              {...props}
              actions={<ListActions />}
              filters={<PaidListFilters />}
            >
              <Datagrid>
                <ReferenceField source="courtId" reference="courts">
                  <TextField source="name" />
                </ReferenceField>
                <NumberField source="price" />
                <NumberField source="penaltyAdded" />
                <ReservationType source="type" />
                <ReservationUserType source="userType" />
                <DateField source="from" />
                <DateField source="to" />
                <EditButton />
              </Datagrid>
            </List>
          </ReferenceManyField>
        </FormTab>
        {/* <FormTab label="Stats" path="stats">
          <Stats props={props}></Stats>
        </FormTab> */}
      </TabbedForm>
    </Edit>
  );
};

export default CourtEdit;
