import * as React from 'react';
import {
  Edit,
  required,
  SimpleForm,
  TextInput,
  email,
  BooleanInput,
  PasswordInput,
  NumberInput,
  minLength
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import countryPhoneCodes from '../constants/countryPhoneCodes.js';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const validatePassword = [minLength(8)];

const useStyles = makeStyles(styles);

const validateEmail = [required(), email()];

const UserEdit = (props) => {
  const classes = useStyles();
  const countryCodes = countryPhoneCodes.map((country) => {
    return {
      id: country.index,
      name: country.en,
    };
  });
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          source='name'
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source='email'
          validate={validateEmail}
          formClassName={classes.inline}
        />
        <TextInput
          source='phone'
          formClassName={classes.inline}
        />
        <PasswordInput
          source='password'
          formClassName={classes.inline}
        />
        <NumberInput source='warnings' formClassName={classes.inline} />
        <NumberInput source='noshowPenalty' label='No show penalty' formClassName={classes.inline} />
        <BooleanInput source='emailVerified' formClassName={classes.inline} />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
