import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  SaveButton,
  DeleteButton,
  Toolbar,
  PasswordInput,
  required,
  minLength,
} from 'react-admin';

import {makeStyles} from '@material-ui/core/styles';

export const styles = {
  inline: {display: 'inline-block', marginLeft: 32},
  margin: {marginLeft: 32, marginRight: 32},
};

const validatePassword = [required(), minLength(8)];

const useStyles = makeStyles(styles);

const StaffTitle = ({record}) => {
  return record ? (
    <span>
      Lecture &quot;
      {record.name}&quot;
    </span>
  ) : null;
};

const redirect = (basePath, id, data) => `/locations/${data.locationId}/staff`;

const CustomToolbar = (props) => {
  return (
    <Toolbar
      {...props}
      classes={useStyles()}
      style={{justifyContent: 'space-between'}}>
      <SaveButton />
      <DeleteButton
        undoable={false}
        redirect={`/locations/${props.record.locationId}/staff`}
      />
    </Toolbar>
  );
};

const CourtEdit = (props) => {
  const classes = useStyles();
  return (
    <Edit title={<StaffTitle />} {...props}>
      <SimpleForm redirect={redirect} toolbar={<CustomToolbar />}>
        <TextInput
          source='name'
          validate={required()}
          formClassName={classes.inline}
        />
        <TextInput
          source='username'
          validate={required()}
          formClassName={classes.inline}
        />
        <PasswordInput
          source='password'
          validate={validatePassword}
          formClassName={classes.inline}
        />
        <ReferenceInput
          source='locationId'
          reference='locations'
          validate={required()}
          formClassName={classes.inline}>
          <SelectInput
            optionText='name'
            optionValue='id'
            formClassName={classes.inline}
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default CourtEdit;
