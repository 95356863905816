import CourtIcon from '@material-ui/icons/Lock';

import CourtList from './CourtList';
import CourtEdit from './CourtEdit';
import CourtCreate from './CourtCreate';

export default {
  // list: CourtList,
  edit: CourtEdit,
  create: CourtCreate,
  icon: CourtIcon,
};
