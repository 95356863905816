import React from 'react';
import {fetchUtils, Admin, Resource} from 'react-admin';
import './App.css';
import authProvider from './auth/authProvider';
import admins from './admins';
import locations from './locations';
import courts from './courts';
import staff from './staff';
import news from './news';
import users from './users';
import unavailability from './unavailability';
import prices from './prices';
import noshow from './noshow';
import paid from './paid';
import myDataProvider from './myDataProvider';
import MyLayout from './components/Layout';
import {theme} from './assets/theme/theme';

function App() {
  //authProvider={authProvider}
  return (
    <Admin
      authProvider={authProvider}
      layout={MyLayout}
      theme={theme}
      dataProvider={myDataProvider}>
      <Resource name='admins' {...admins} />
      <Resource name='locations' {...locations} />
      <Resource name='courts' {...courts} />
      <Resource name='staff' {...staff} />
      <Resource name='news' {...news} />
      <Resource name='users' {...users} />
      <Resource name='unavailability' {...unavailability} />
      <Resource name='prices' {...prices} />
      <Resource name='noshow' {...noshow} />
      <Resource name='paid' {...paid} />
    </Admin>
  );
}

export default App;
