import {makeStyles} from '@material-ui/core/styles';
import * as React from 'react';
import {cloneElement} from 'react';
import {
  CreateButton,
  EditButton,
  ExportButton,
  List,
  ListButton,
  Datagrid,
  TextField,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
} from 'react-admin';

// import LinkToRelatedProducts from './LinkToRelatedProducts';
// import {Category} from '../types';

const useStyles = makeStyles({
  root: {
    marginTop: '1em',
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
  },
  title: {
    paddingBottom: '0.5em',
  },
  actionSpacer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
});

const ActiveField = ({record, source}) => {
  record = {
    [source]: record?.active == 0 ? 'No' : 'Yes',
  };
  return <TextField record={record} source={source} />;
};

const FeaturedField = ({record, source}) => {
  record = {
    [source]: record?.isFeatured == 0 ? 'No' : 'Yes',
  };
  return <TextField record={record} source={source} label='Featured' />;
};

const ListActions = (props) => {
  const {className, exporter, filters, maxResults, ...rest} = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

const TimeField = ({record, source}) => {
  if (record) {
    record = {
      [source]: tConvert(record[source]),
    };
  }
  return <TextField record={record} source={source} />;
};

export const LocationList = (props) => (
  <List {...props} actions={<ListActions />}>
    <Datagrid>
      <TextField source='name' />
      <TextField source='address' />
      <TextField source='phone' />
      <TextField source='email' />
      <ActiveField source='active' />
      <FeaturedField source='isFeatured' label='Featured' />
      <TimeField source='workingHoursFrom' />
      <TimeField source='workingHoursTo' />
      <EditButton />
    </Datagrid>
  </List>
);

export default LocationList;
